import { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

const labelPadding = 5;
const valueLabelPadding = 5;
const valueLabelWidth = 40;

function BarChart({
  data, getColor, formatValue, domain, sort, labelWidth, barHeight, barPaddingInner, barPaddingOuter,
}) {
  const theme = useTheme();

  const [svg, setSvg] = useState();
  const [triggerRender, setTriggerRender] = useState({});

  const width = useMemo(() => (
    svg == null ? null : svg.getBoundingClientRect().width
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [svg, triggerRender]);

  useEffect(() => {
    function handleResize() {
      setTriggerRender({});
    }

    window.addEventListener('resize', handleResize);

    return () => { window.removeEventListener('resize', handleResize); };
  }, []);

  const height = (barHeight * data.length) / (1 - barPaddingInner - barPaddingOuter);

  const scaleX = useMemo(() => (
    d3.scaleLinear()
      .domain(domain ?? [0, Math.max(...data.map((d) => d.value))])
      .range([0, width == null ? 0 : Math.max(width - labelWidth - labelPadding - valueLabelWidth, 0)])
  ), [data, domain, labelWidth, width]);

  const scaleY = useMemo(() => (
    d3.scaleBand()
      .domain((sort == null ? data : [...data].sort(sort)).map((d) => d.key))
      .range([0, height])
      .paddingInner(barPaddingInner)
      .paddingOuter(barPaddingOuter)
  ), [barPaddingInner, barPaddingOuter, data, height, sort]);

  const labels = useMemo(() => (
    data.map((d) => (
      <foreignObject
        key={d.key}
        y={scaleY(d.key) - (scaleY.step() - scaleY.bandwidth()) / 2}
        width={labelWidth}
        height={scaleY.step()}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
        }}
        >
          <Typography variant="body2" align="right">{d.key}</Typography>
        </Box>
      </foreignObject>
    ))
  ), [data, labelWidth, scaleY]);

  const valueLabels = useMemo(() => (
    data.map((d) => (
      <text
        key={d.key}
        x={scaleX(d.value) + valueLabelPadding}
        y={scaleY(d.key) + scaleY.bandwidth() / 2}
        dominantBaseline="central"
        style={{
          fontFamily: theme.typography.body2.fontFamily,
          fontSize: theme.typography.body2.fontSize,
          fill: theme.palette.text.primary,
        }}
      >
        {formatValue == null ? d.value : formatValue(d.value)}
      </text>
    ))
  ), [data, formatValue, scaleX, scaleY,
    theme.palette.text.primary, theme.typography.body2.fontFamily, theme.typography.body2.fontSize]);

  const bars = useMemo(() => (
    data.map((d) => (
      <rect
        key={d.key}
        y={scaleY(d.key)}
        width={scaleX(d.value)}
        height={scaleY.bandwidth()}
        fill={getColor == null ? theme.palette.text.primary : getColor(d)}
      />
    ))
  ), [data, getColor, scaleX, scaleY, theme.palette.text.primary]);

  return (
    <svg ref={setSvg} width="100%" height={height}>
      <g>
        {labels}
      </g>
      <g transform={`translate(${labelWidth + labelPadding},0)`}>
        {bars}
      </g>
      <g transform={`translate(${labelWidth + labelPadding},0)`}>
        {valueLabels}
      </g>
    </svg>
  );
}

BarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  })).isRequired,
  domain: PropTypes.arrayOf(PropTypes.number),
  formatValue: PropTypes.func,
  labelWidth: PropTypes.number,
  getColor: PropTypes.func,
  sort: PropTypes.func,
  barHeight: PropTypes.number,
  barPaddingInner: PropTypes.number,
  barPaddingOuter: PropTypes.number,
};

BarChart.defaultProps = {
  domain: null,
  formatValue: null,
  getColor: null,
  labelWidth: 500,
  sort: null,
  barHeight: 20,
  barPaddingInner: 0.5,
  barPaddingOuter: 0.1,
};

export default BarChart;
