import { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import AttributeRangeSlider from 'components/AttributeRangeSlider';
import AttributeSelect from 'components/AttributeSelect';
import CollapsibleSection from 'components/CollapsibleSection';
import NoData from 'components/NoData';
import Panel from 'components/Panel';
import { compare } from 'lib/utils';
import { findAttribute, openEndedAttributes } from 'lib/common';

function getAttributeStructure(attributes, responses) {
  const attributeStructure = {};

  attributes.filter((a) => a.isFilter)
    .forEach((a) => {
      const atCategory = attributeStructure[a.category] || {};
      if (openEndedAttributes.includes(a.type)) {
        const atType = responses.map((r) => (
          r.openEndeds
            .filter((o) => o.id === a.id)
            .map((o) => o.openEnded)
        )).flat()
          .filter((value) => value != null);
        atCategory[a.type] = atType;
      } else {
        const atType = atCategory[a.type] || [];
        atType.push(a.attribute);
        atCategory[a.type] = atType;
      }
      attributeStructure[a.category] = atCategory;
    });

  return Object.entries(attributeStructure).map(([category, types]) => ({
    category,
    types: Object.entries(types).map(([type, attributeOptions]) => ({
      type,
      attributeOptions: [...attributeOptions].sort((a, b) => (
        compare(
          findAttribute(attributes, category, type, a)?.id ?? a,
          findAttribute(attributes, category, type, b)?.id ?? b,
        )
      )),
    })),
  }));
}

function AttributesPanel({ onSubmit, attributes, responses }) {
  const attributeStructure = useMemo(() => (
    attributes == null || responses == null ? null : getAttributeStructure(attributes, responses)
  ), [attributes, responses]);

  const [attributeSelections, setAttributeSelections] = useState({});

  const handleAttributeSelection = useCallback((attributeType, attributeValue) => {
    setAttributeSelections({
      ...attributeSelections,
      [attributeType]: attributeValue === '' ? null : attributeValue,
    });
  }, [attributeSelections]);

  const resetFields = useCallback(() => {
    setAttributeSelections({});
  }, []);

  return (
    <Panel
      title="Business Attributes"
      titleTooltip="Characteristics of businesses or business owners that inform behaviors."
      buttons={[<Button key="reset" color="primary" onClick={resetFields}>Reset</Button>]}
    >
      {attributeStructure == null ? <NoData />
        : (
          <Stack spacing={2}>
            {attributeStructure.map(({ category, types }) => (
              <CollapsibleSection key={category} title={category}>
                <Stack spacing={1}>
                  {types.map(({ type, attributeOptions }) => (
                    openEndedAttributes.includes(type)
                      ? (
                        <AttributeRangeSlider
                          key={type}
                          label={type}
                          options={attributeOptions.map((option) => Number.parseInt(option, 10))}
                          onChange={((value) => { handleAttributeSelection(type, value); })}
                        />
                      )
                      : (
                        <AttributeSelect
                          key={type}
                          label={type}
                          options={['', ...attributeOptions]}
                          value={attributeSelections[type] || ''}
                          onChange={(value) => { handleAttributeSelection(type, value); }}
                        />
                      )
                  ))}
                </Stack>
              </CollapsibleSection>
            ))}
            <Button
              color="primary"
              variant="contained"
              endIcon={<ArrowRightAltIcon />}
              onClick={() => {
                onSubmit(attributeSelections);
              }}
            >
              Submit
            </Button>
          </Stack>
        )}
    </Panel>
  );
}

AttributesPanel.propTypes = {
  attributes: PropTypes.arrayOf(PropTypes.shape({
    category: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    attribute: PropTypes.string,
  })),
  onSubmit: PropTypes.func.isRequired,
  responses: PropTypes.arrayOf(PropTypes.shape({
    openEndeds: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      openEnded: PropTypes.string.isRequired,
    })),
  })),
};

AttributesPanel.defaultProps = {
  attributes: null,
  responses: null,
};

export default AttributesPanel;
