import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

function OpenEndedExtra({ answers }) {
  return (
    <Box>
      <List disablePadding dense>
        {answers.map((answer, i) => (
          <ListItem key={`${i}-answer`} disableGutters>
            <ListItemText>{answer}</ListItemText>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

OpenEndedExtra.propTypes = {
  answers: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default OpenEndedExtra;
