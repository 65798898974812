import { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

function SortButton({ options }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const isOpen = Boolean(anchorEl);

  return (
    <>
      <Button
        color="primary"
        onClick={(e) => { setAnchorEl(e.target); }}
      >
        Sort
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={() => { setAnchorEl(null); }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.label}
            onClick={() => { setAnchorEl(null); option.onClick(); }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

SortButton.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  })).isRequired,
};

export default SortButton;
