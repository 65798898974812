import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';

function MoreInfoButton({ onClick }) {
  return (
    <IconButton aria-label="more info" color="accent" onClick={onClick}><InfoIcon /></IconButton>
  );
}

MoreInfoButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default MoreInfoButton;
