import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as UrbaneLogo } from 'lib/images/urbane-logo.svg';
import HelpIcon from '@mui/icons-material/Help';

function AppBar({ onHelpClick }) {
  return (
    <Box marginBottom={1}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <UrbaneLogo height={32} />
        <IconButton size="medium" onClick={onHelpClick}><HelpIcon fontSize="inherit" /></IconButton>
      </Box>
      <Divider sx={{ marginTop: 2 }} />
    </Box>
  );
}

AppBar.propTypes = {
  onHelpClick: PropTypes.func.isRequired,
};

export default AppBar;
