import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

function TopTab({ children }) {
  return (
    <Box sx={{
      position: 'fixed',
      top: 0,
      left: '50%',
      transform: 'translate(-50%)',
      borderLeftStyle: 'solid',
      borderRightStyle: 'solid',
      borderBottomStyle: 'solid',
      borderColor: 'divider',
      borderWidth: 1,
      borderBottomLeftRadius: (theme) => theme.shape.borderRadius,
      borderBottomRightRadius: (theme) => theme.shape.borderRadius,
      paddingLeft: {
        xs: 1,
        sm: 3,
        md: 6,
      },
      paddingRight: {
        xs: 1,
        sm: 3,
        md: 6,
      },
      paddingTop: 1,
      paddingBottom: 1,
      display: 'flex',
      justifyContent: 'center',
      bgcolor: 'rgba(255, 255, 255,.9)',
      zIndex: 100,
    }}
    >
      {children}
    </Box>
  );
}

TopTab.propTypes = {
  children: PropTypes.node,
};

TopTab.defaultProps = {
  children: null,
};

export default TopTab;
