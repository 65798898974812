import { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material';
import { darkTheme } from 'theme';
import CloseIcon from '@mui/icons-material/Close';

export const ExtraContext = createContext();

function Extra({
  children, isOpen, title, subtitle,
}) {
  const setExtraContent = useContext(ExtraContext);

  return (
    <ThemeProvider theme={darkTheme}>
      <SwipeableDrawer
        open={isOpen}
        disableSwipeToOpen
        onOpen={() => { setExtraContent({ isOpen: true }); }}
        onClose={() => { setExtraContent({ isOpen: false }); }}
        sx={{ flexShrink: 0 }}
        PaperProps={{
          sx: {
            minWidth: 'min(400px, 100%)',
            width: '50vw',
            maxWidth: 700,
            padding: 4,
            backgroundImage: 'none',
          },
        }}
        transitionDuration={500}
        disableBackdropTransition
      >
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ flexGrow: 1 }}>{title && <Typography variant="h4" gutterBottom>{title}</Typography>}</Box>
          <Box><IconButton color="secondary" onClick={() => { setExtraContent({ isOpen: false }); }}><CloseIcon /></IconButton></Box>
        </Box>
        {subtitle && <Typography variant="h6" gutterBottom>{subtitle}</Typography>}
        {(title || subtitle) && <Divider sx={{ marginBottom: 2 }} />}
        {children}
      </SwipeableDrawer>
    </ThemeProvider>
  );
}

Extra.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

Extra.defaultProps = {
  children: null,
  subtitle: null,
  title: null,
};

export default Extra;
