export const openEndedAttributes = [
  'Years in business',
];

export function getInfluencersData(responses) {
  const responsesByInfluencer = {};

  responses.forEach((r) => {
    r.influencers.forEach((i) => {
      if (responsesByInfluencer[i.influencer] == null) {
        responsesByInfluencer[i.influencer] = new Set([r.id]);
      } else {
        responsesByInfluencer[i.influencer].add(r.id);
      }
      if (i.subtype != null) {
        const influencerAndSubtype = `${i.influencer}-${i.subtype}`;
        if (responsesByInfluencer[influencerAndSubtype] == null) {
          responsesByInfluencer[influencerAndSubtype] = new Set([r.id]);
        } else {
          responsesByInfluencer[influencerAndSubtype].add(r.id);
        }
      }
    });
  });

  return Object.entries(responsesByInfluencer)
    .map(([influencer, responsesAtInfluencer]) => ({
      key: influencer,
      value: responsesAtInfluencer.size / responses.length,
    }));
}

export function getPractices(responses) {
  const practices = {};
  const responsesByPractice = {};

  responses.forEach((r) => {
    r.practices.forEach((p) => {
      practices[p.id] = p;
      if (responsesByPractice[p.id] == null) responsesByPractice[p.id] = new Set();
      responsesByPractice[p.id].add(r.id);
    });
  });

  return Object.entries(practices).map(([id, p]) => ({
    ...p,
    frequency: responsesByPractice[id].size / responses.length,
  }));
}

export function getStrategies(responses) {
  const strategies = {};
  const responsesByStrategy = {};

  responses.forEach((r) => {
    r.strategies.forEach((s) => {
      strategies[s.id] = s;
      if (responsesByStrategy[s.id] == null) responsesByStrategy[s.id] = new Set();
      responsesByStrategy[s.id].add(r.id);
    });
  });

  return Object.entries(strategies).map(([id, s]) => ({
    ...s,
    frequency: responsesByStrategy[id].size / responses.length,
  }));
}

export function findAttribute(attributes, category, type, attribute) {
  return attributes.find((a) => (
    (category == null || a.category === category)
    && (type == null || a.type === type)
    && (attribute == null || Array.isArray(attribute) || a.attribute === attribute)
  ));
}
