import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { getSum } from 'lib/utils';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Tooltip from 'components/Tooltip';

const generateArc = d3.arc()
  .innerRadius(20)
  .outerRadius(50)
  .padAngle(0.02)
  .cornerRadius(1);

function getViewScale(svg) {
  if (svg == null) return d3.scaleLinear().domain([0, 100]).range([0, 100]);
  const { width, height } = svg.getBoundingClientRect();
  return d3.scaleLinear().domain([0, 100]).range([0, Math.max(width, height)]);
}

function Speedometer({ data, getColor, formatValue }) {
  const [svg, setSvg] = useState();
  const [tooltipPosition, setTooltipPosition] = useState(null);
  const [tooltipText, setTooltipText] = useState();

  const { left, top } = svg == null ? {} : svg.getBoundingClientRect();

  const [triggerRender, setTriggerRender] = useState({});

  const viewScale = useMemo(() => (
    getViewScale(svg)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [svg, triggerRender]);

  useEffect(() => {
    function handleResize() {
      setTriggerRender({});
    }

    window.addEventListener('resize', handleResize);

    return () => { window.removeEventListener('resize', handleResize); };
  }, []);

  useEffect(() => {
    function handleScroll() {
      setTriggerRender({});
    }

    window.addEventListener('scroll', handleScroll);

    return () => { window.removeEventListener('scroll', handleScroll); };
  }, []);

  const scaleAngle = d3.scaleLinear()
    .domain([0, getSum(data.map((d) => d.value))])
    .range([-Math.PI / 2, Math.PI / 2]);

  const segments = useMemo(() => {
    let currentSum = 0;
    return data.map((d) => {
      const arcArgs = {
        startAngle: scaleAngle(currentSum),
        endAngle: scaleAngle(currentSum + d.value),
      };
      const centroid = generateArc.centroid(arcArgs);
      const segment = (
        <path
          key={d.key}
          d={generateArc(arcArgs)}
          fill={getColor(d)}
          onMouseEnter={() => {
            setTooltipPosition([viewScale(50 + centroid[0]), viewScale(50 + centroid[1])]);
            setTooltipText(formatValue == null ? d.value : formatValue(d.value));
          }}
          onMouseLeave={() => {
            setTooltipPosition(null);
            setTooltipText(null);
          }}
        />
      );
      currentSum += d.value;
      return segment;
    });
  }, [data, formatValue, getColor, scaleAngle, viewScale]);

  return (
    <>
      <Grid container sx={{ width: '100%', height: '100%' }}>
        <Grid item xs={6}>
          <Stack spacing={2}>
            {data.map((d) => (
              <Stack key={d.key} direction="row" spacing={1}>
                <Box sx={{
                  width: (theme) => theme.spacing(2),
                  backgroundColor: getColor(d),
                }}
                />
                <Typography variant="body2">{d.key}</Typography>
              </Stack>
            ))}
          </Stack>
        </Grid>
        <Grid item xs={6} sx={{ height: '100%', width: '100%' }}>
          <svg ref={setSvg} height="100%" viewBox="0 0 100 50">
            <g transform="translate(50, 50)">
              {segments}
            </g>
          </svg>
        </Grid>
      </Grid>
      {tooltipPosition && (
      <Tooltip
        position={[left + tooltipPosition[0], top + tooltipPosition[1]]}
        text={tooltipText}
      />
      )}
    </>
  );
}

Speedometer.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  })).isRequired,
  formatValue: PropTypes.func,
  getColor: PropTypes.func.isRequired,
};

Speedometer.defaultProps = {
  formatValue: null,
};

export default Speedometer;
