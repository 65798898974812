import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

function Panel({
  title, titleTooltip, buttons, children, isMinimised, onMinimisedChange,
}) {
  return (
    <>
      <Box
        square
        elevation={4}
        sx={{
          bgcolor: 'white',
          paddingLeft: {
            xs: 4,
            md: 8,
          },
          paddingRight: {
            xs: 4,
            md: 8,
          },
          paddingTop: {
            xs: 4,
            md: isMinimised ? 4 : 8,
          },
          paddingBottom: {
            xs: 4,
            md: isMinimised ? 4 : 8,
          },
          flex: '1 1 auto',
          boxShadow: '8px 8px 8px rgba(0,0,0,.07)',
          maxWidth: '100%',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Tooltip
            title={titleTooltip}
            placement="top-start"
            enterDelay={800}
            enterNextDelay={800}
          >
            <Typography
              variant="h4"
              gutterBottom
              sx={{ cursor: titleTooltip == null ? 'default' : 'help' }}
            >
              {title}
            </Typography>
          </Tooltip>
          {onMinimisedChange && (
          <IconButton onClick={onMinimisedChange}>
            {isMinimised ? <AddIcon /> : <RemoveIcon />}
          </IconButton>
          )}
        </Box>
        {isMinimised ? null : (
          <>
            {buttons && (
            <Box sx={{
              position: 'relative',
              left: (theme) => theme.spacing(-1),
            }}
            >
              {buttons}
            </Box>
            )}
            <Box sx={{ marginBottom: 4 }} />
            {children}
          </>
        )}
      </Box>
    </>
  );
}

Panel.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.node),
  children: PropTypes.node,
  isMinimised: PropTypes.bool,
  onMinimisedChange: PropTypes.func,
  title: PropTypes.string.isRequired,
  titleTooltip: PropTypes.string,
};

Panel.defaultProps = {
  buttons: null,
  children: null,
  isMinimised: false,
  onMinimisedChange: null,
  titleTooltip: null,
};

export default Panel;
