import { trim, inRange } from 'lib/utils';
import { openEndedAttributes } from 'lib/common';

function meetsAttributeRequirement(response, filters, attributes) {
  return Object.entries(filters).filter(([, attribute]) => attribute != null)
    .every(([type, attribute]) => {
      if (openEndedAttributes.includes(type)) {
        const attributeId = attributes.find((a) => a.type === type).id;
        return response.openEndeds.some((o) => (
          o.id === attributeId && inRange(attribute[0], attribute[1], Number.parseInt(o.openEnded, 10), true)
        ));
      }
      return response.attributes.some((a) => (
        a.type === type && a.attribute === attribute
      ));
    });
}

function meetsInfluencerRequirement(response, influencer) {
  return influencer == null || response.influencers.some((i) => i.id === influencer);
}

function meetsPracticeRequirement(response, practice) {
  return practice == null || response.practices.some((p) => p.id === practice);
}

function meetsStrategyRequirement(response, strategy) {
  return strategy == null || response.strategies.some((s) => s.id === strategy);
}
export function getFilteredResponses(responses, filters, attributes) {
  return responses.filter((r) => (
    meetsAttributeRequirement(r, filters.attributes, attributes)
    && meetsInfluencerRequirement(r, filters.influencer)
    && meetsPracticeRequirement(r, filters.practice)
    && meetsStrategyRequirement(r, filters.strategy)
  ));
}

export function transformRawDataToResponses(data) {
  return clean(data.survey_response.map((r) => ({
    id: r.respondent_id,
    attributes: r.response_attributes.map((a) => ({
      id: a.attribute_id,
      category: a.attribute.attribute_category,
      type: a.attribute.attribute_type,
      attribute: a.attribute.attribute,
    })),
    influencers: r.response_influencers.map((i) => ({
      id: i.influencer_id,
      influencer: i.influencer.influencer,
      subtype: i.influencer.influencer_subtype,
    })),
    practices: r.response_practices.map((p) => ({
      id: p.practice_id,
      category: p.practice.practice_category,
      practice: p.practice.practice,
    })),
    strategies: r.response_strategies.map((s) => ({
      id: s.strategy_id,
      strategy: s.strategy.strategy,
      tactic: s.strategy.tactic,
    })),
    mindsets: r.response_mindsets.map((m) => ({
      id: m.mindset_id,
      question: m.mindset.question,
      answer: trim(m.mindset.answer),
    })),
    openEndeds: r.response_open_endeds.map((o) => ({
      id: o.attribute_id ?? o.influencer_id ?? o.practice_id ?? o.strategy_id ?? o.mindset_id,
      openEnded: o.open_ended,
    })),
  })));
}

export function transformRawDataToMindsets(data) {
  return clean(data.mindsets.map((m) => ({
    id: m.database_code,
    question: m.question,
    answer: trim(m.answer),
    chartType: m.chart_type,
  })));
}

export function transformRawDataToAttributes(data) {
  return clean(data.attributes.map((a) => ({
    id: a.database_code,
    category: a.attribute_category,
    type: a.attribute_type,
    attribute: a.attribute,
    isFilter: a.is_filter,
  })));
}

export function transformRawDataToInfluencers(data) {
  return clean(data.influencers.map((i) => ({
    id: i.database_code,
    influencer: i.influencer,
    subtype: i.influencer_subtype,
  })));
}

export function transformRawDataToPractices(data) {
  return clean(data.practices.map((p) => ({
    id: p.database_code,
    category: p.practice_category,
    practice: p.practice,
  })));
}

export function transformRawDataToStrategies(data) {
  return clean(data.strategies.map((s) => ({
    id: s.database_code,
    strategy: s.strategy,
    tactic: s.tactic,
  })));
}

function clean(thing) {
  if (thing == null) return thing;

  if (typeof thing === 'string') {
    return thing.trim()
      .replace(/\s/g, ' ')
      .replace(/\u2019/, "'");
  }

  if (typeof thing === 'number') {
    return String(thing);
  }

  if (Array.isArray(thing)) {
    return cleanArray(thing);
  }

  if (typeof thing === 'object') {
    return cleanObject(thing);
  }

  return thing;
}

function cleanArray(array) {
  return array.map(clean);
}

function cleanObject(object) {
  return Object.fromEntries(
    Object.entries(object).map(([key, value]) => (
      [clean(key), clean(value)]
    )),
  );
}
