import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

function ClearSelectionButton({ isDisabled, onClick }) {
  return (
    <Button color="primary" disabled={isDisabled} onClick={onClick}>Clear</Button>
  );
}

ClearSelectionButton.propTypes = {
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

ClearSelectionButton.defaultProps = {
  isDisabled: false,
};

export default ClearSelectionButton;
