import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

function AttributeSelect({
  label, options, value, onChange,
}) {
  return (
    <TextField
      label={label}
      value={value}
      onChange={(e) => { onChange(e.target.value); }}
      select
      variant="standard"
    >
      {options.map((option) => (
        <MenuItem key={option} value={option}>{option === '' ? '\u00A0' : option}</MenuItem>
      ))}
    </TextField>
  );
}

AttributeSelect.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.string.isRequired,
};

export default AttributeSelect;
