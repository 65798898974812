import { useContext } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';
import DataContext from 'components/DataContext';
import FiltersContext from 'components/FiltersContext';
import ResponseContext from 'components/ResponseContext';
import { compare } from 'lib/utils';
import { findAttribute } from 'lib/common';

function ResponsesExtra() {
  const {
    attributes, influencers, practices, strategies,
  } = useContext(DataContext);
  const responses = useContext(ResponseContext);
  const filters = useContext(FiltersContext);

  const influencer = filters.influencer == null ? null : influencers.find((i) => i.id === filters.influencer);
  const practice = filters.practice == null ? null : practices.find((p) => p.id === filters.practice);
  const strategy = filters.strategy == null ? null : strategies.find((s) => s.id === filters.strategy);

  return (
    <Stack spacing={4}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Stack align="center">
          <Typography variant="h5">{responses.length}</Typography>
          <Typography variant="subtitle">filtered responses</Typography>
        </Stack>
      </Box>
      <Box>
        <Typography variant="h5" sx={{ marginBottom: 2 }}>Applied Filters</Typography>
        <Stack spacing={2}>
          <Box>
            <TableContainer>
              <Table>
                <TableBody>
                  {
                    [...Object.entries(filters.attributes)]
                      .filter(([, attribute]) => attribute != null)
                      .sort(([aType, aAttribute], [bType, bAttribute]) => (
                        compare(
                          findAttribute(attributes, null, aType, aAttribute).id,
                          findAttribute(attributes, null, bType, bAttribute).id,
                        )
                      ))
                      .map(([type, attribute]) => (
                        <TableRow key={type}>
                          <TableCell sx={{ paddingLeft: 0 }}>{type}</TableCell>
                          <TableCell>{Array.isArray(attribute) ? attribute.join(' - ') : attribute}</TableCell>
                        </TableRow>
                      ))
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box>
            <Typography variant="h6">Influencers</Typography>
            <Typography>{influencer == null ? '-' : [influencer.influencer, influencer.subtype].filter((i) => i != null).join(' | ')}</Typography>
          </Box>
          <Box>
            <Typography variant="h6">Employment Practices</Typography>
            <Typography>{practice == null ? '-' : [practice.category, practice.practice].filter((p) => p != null).join(' | ')}</Typography>
          </Box>
          <Box>
            <Typography variant="h6">Strategies</Typography>
            <Typography>{strategy == null ? '-' : [strategy.strategy, strategy.tactic].filter((s) => s != null).join(' | ')}</Typography>
          </Box>
        </Stack>
      </Box>
    </Stack>
  );
}

export default ResponsesExtra;
