import { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import MuiPaper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { ExtraContext } from 'components/Extra';
import BarChart from 'components/BarChart';
import MoreInfoButton from 'components/MoreInfoButton';
import OpenEndedExtra from 'components/OpenEndedExtra';
import Panel from 'components/Panel';
import Speedometer from 'components/Speedometer';
import WordCloud from 'components/WordCloud';
import NoData from 'components/NoData';
import ResponseContext from 'components/ResponseContext';
import { styled, useTheme } from '@mui/material/styles';
import * as d3 from 'd3';

const mindsetAnswersToColors = {
  'Not important': '#f44336',
  'Somewhat important': '#ffc107',
  'Very important': '#03a9f4',
  Yes: '#03a9f4',
  No: '#f44336',
};

function getSpeedometers(mindsets, responses) {
  const speedometers = {};
  mindsets.forEach((m) => {
    speedometers[m.question] = speedometers[m.question] || [];
    speedometers[m.question].push({
      answer: m.answer,
      value: responses.filter((r) => (
        r.mindsets.some((rm) => (
          rm.question === m.question && rm.answer === m.answer
        ))
      )).length / responses.length,
    });
  });
  return Object.entries(speedometers).map(([question, answers]) => ({
    question,
    answers,
  }));
}

function getBarCharts(mindsets, responses) {
  const barCharts = {};
  mindsets.forEach((m) => {
    barCharts[m.question] = barCharts[m.question] || [];
    barCharts[m.question].push({
      answer: m.answer,
      value: responses.filter((r) => (
        r.mindsets.some((rm) => (
          rm.question === m.question && rm.answer === m.answer
        ))
      )).length / responses.length,
    });
  });
  return Object.entries(barCharts).map(([question, answers]) => ({
    question,
    answers,
  }));
}

function getWordClouds(mindsets, responses) {
  const wordClouds = {};
  mindsets.forEach((m) => {
    wordClouds[m.question] = responses.map((r) => (
      r.openEndeds.find((ro) => (
        ro.id === m.id
      ))
    )).filter(Boolean)
      .map((o) => o.openEnded);
  });
  return Object.entries(wordClouds).map(([question, answers]) => ({
    question,
    answers,
  }));
}

function getMindsetStructure(mindsets, responses) {
  return {
    speedometers: getSpeedometers(mindsets.filter((m) => m.chartType === 'speedometer'), responses),
    barCharts: getBarCharts(mindsets.filter((m) => m.chartType === 'bar'), responses),
    wordClouds: getWordClouds(mindsets.filter((m) => m.chartType === 'word cloud'), responses),
  };
}

const Paper = styled(MuiPaper)(({ theme }) => ({
  padding: theme.spacing(2),
  boxShadow: 'none',
  borderColor: theme.palette.divider,
  borderStyle: 'solid',
  borderWidth: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  minWidth: 400,
}));

function MindsetsPanel({ mindsets, isMinimised, onMinimisedChange }) {
  const theme = useTheme();

  const responses = useContext(ResponseContext);
  const setExtraProps = useContext(ExtraContext);

  const mindsetStructure = useMemo(() => (
    mindsets == null || responses == null ? null : getMindsetStructure(mindsets, responses)
  ), [mindsets, responses]);

  return (
    <Panel
      title="Mindsets"
      titleTooltip="The behaviors and thoughts that govern the actions of business owners."
      isMinimised={isMinimised}
      onMinimisedChange={onMinimisedChange}
    >
      {mindsetStructure == null || responses.length === 0 ? <NoData />
        : (
          <Grid container spacing={4} justifyContent="space-between" sx={{ overflowX: 'auto' }}>
            {mindsetStructure.speedometers.map(({ question, answers }) => (
              <Grid key={question} item xs={12} sx={{ display: 'flex' }}>
                <Paper>
                  <Typography variant="body1" sx={{ marginBottom: 2 }}>{question}</Typography>
                  <Box sx={{ height: 100 }}>
                    <Speedometer
                      getColor={(d) => mindsetAnswersToColors[d.key]}
                      formatValue={d3.format('.0%')}
                      data={answers.map(({ answer, value }) => ({
                        key: answer,
                        value,
                      }))}
                    />
                  </Box>
                </Paper>
              </Grid>
            ))}
            { mindsetStructure.barCharts.map(({ question, answers }) => (
              <Grid key={question} item xs={12}>
                <Paper>
                  <Stack spacing={2}>
                    <Typography variant="body1" align="center">{question}</Typography>
                    <BarChart
                      data={answers.map(({ answer, value }) => ({
                        key: answer,
                        value,
                      }))}
                      getColor={() => theme.palette.accent.main}
                      formatValue={d3.format('.0%')}
                      domain={[0, 1]}
                      sort={(a, b) => b.value - a.value}
                      labelWidth={280}
                      barHeight={30}
                    />
                  </Stack>
                </Paper>
              </Grid>
            ))}
            {mindsetStructure.wordClouds.map(({ question, answers }) => (
              <Grid key={question} item xs={12}>
                <Paper key={question}>
                  <Stack spacing={0}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Box sx={{ flex: 1 }} />
                      <Typography variant="body1">{question}</Typography>
                      <Box sx={{
                        flex: 1,
                        display: 'flex',
                        justifyContent: 'flex-end',
                        transform: `translate(0,${theme.spacing(-1)})`,
                      }}
                      >
                        <MoreInfoButton onClick={() => {
                          setExtraProps({
                            isOpen: true,
                            title: question,
                            subtitle: 'Individual Answers',
                            children: <OpenEndedExtra answers={answers} />,
                          });
                        }}
                        />
                      </Box>
                    </Box>
                    <WordCloud data={answers} />
                  </Stack>
                </Paper>
              </Grid>
            ))}
          </Grid>
        )}
    </Panel>
  );
}

MindsetsPanel.propTypes = {
  mindsets: PropTypes.arrayOf(PropTypes.shape({
    question: PropTypes.string.isRequired,
    answer: PropTypes.string,
  })),
  isMinimised: PropTypes.bool,
  onMinimisedChange: PropTypes.func,
};

MindsetsPanel.defaultProps = {
  mindsets: null,
  isMinimised: false,
  onMinimisedChange: null,
};

export default MindsetsPanel;
