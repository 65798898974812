import { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import FrequencyIndicator from 'components/FrequencyIndicator';
import MoreInfoButton from 'components/MoreInfoButton';
import { useTheme } from '@mui/material/styles';

function Item({
  text, detail, onClick, value, isSelected, isDisabled, onMoreInfoClick, type,
}) {
  const theme = useTheme();

  const [isMoreInfoHovered, setIsMoreInfoHovered] = useState(false);

  return (
    <ListItemButton
      sx={{
        paddingTop: 2,
        paddingBottom: 2,
        '&:hover': {
          backgroundColor: isMoreInfoHovered ? 'transparent' : 'undefined',
        },
      }}
      divider
      onClick={onClick}
      selected={isSelected}
      disabled={isDisabled}
    >
      {onMoreInfoClick && (
      <ListItemAvatar
        onMouseEnter={(e) => { setIsMoreInfoHovered(true); e.stopPropagation(); }}
        onMouseLeave={(e) => { setIsMoreInfoHovered(false); e.stopPropagation(); }}
        onMouseDown={(e) => { e.stopPropagation(); }}
      >
        <MoreInfoButton onClick={(e) => { e.stopPropagation(); onMoreInfoClick(); }} />
      </ListItemAvatar>
      )}
      <ListItemText sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Box sx={{ flexGrow: 1 }}>
          {detail && <Typography variant="overline" component="p" gutterBottom>{detail}</Typography>}
          <Typography variant="body1">{text}</Typography>
        </Box>
      </ListItemText>
      <ListItemAvatar sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Tooltip
          title={`The percentage of filtered responses that are linked to this particular ${type}.`}
          placement="top-start"
          enterDelay={800}
          enterNextDelay={800}
        >
          <Box>
            <FrequencyIndicator
              value={value}
              color={theme.palette.grey.light}
              width={15}
              height={30}
            />
          </Box>
        </Tooltip>
      </ListItemAvatar>
    </ListItemButton>
  );
}

Item.propTypes = {
  detail: PropTypes.string,
  isDisabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  onMoreInfoClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.number,
};

Item.defaultProps = {
  detail: null,
  isDisabled: false,
  isSelected: false,
  onClick: null,
  onMoreInfoClick: null,
  value: null,
};

export default Item;
