import { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
// import { ExtraContext } from 'components/Extra';
import ClearSelectionButton from 'components/ClearSelectionButton';
import FiltersContext from 'components/FiltersContext';
import Item from 'components/Item';
import NoData from 'components/NoData';
import Panel from 'components/Panel';
import ResponseContext from 'components/ResponseContext';
import SortButton from 'components/SortButton';
// import StrategyExtra from 'components/StrategyExtra';
import { sortBy } from 'lib/utils';
import { getStrategies } from 'lib/common';

const primarySortKeyToKeys = {
  strategy: ['strategy', 'frequency,', 'tactic'],
  tactic: ['tactic', 'frequency', 'strategy'],
  frequency: ['frequency', 'strategy', 'tactic'],
};

const keyToOrder = {
  strategy: 'asc',
  tactic: 'asc',
  frequency: 'desc',
};

function StrategiesPanel({
  isMinimised, onMinimisedChange, onSelect,
}) {
  // const setExtraProps = useContext(ExtraContext);
  const responses = useContext(ResponseContext);
  const filters = useContext(FiltersContext);

  const [primarySortKey, setPrimarySortKey] = useState('frequency');

  const strategies = useMemo(() => (
    responses == null ? null : getStrategies(responses)
  ), [responses]);

  const sortedStrategies = useMemo(() => (
    strategies == null ? null : sortBy(
      strategies,
      primarySortKeyToKeys[primarySortKey],
      primarySortKeyToKeys[primarySortKey].map((key) => keyToOrder[key]),
    )
  ), [primarySortKey, strategies]);

  return (
    <Panel
      title="Strategies"
      titleTooltip="Tactical and strategic approaches that can influence or incentivize business owners to adopt mobility-boosting employment practices."
      isMinimised={isMinimised}
      onMinimisedChange={onMinimisedChange}
      buttons={[
        <SortButton
          key="sort"
          options={[
            {
              label: 'By Strategy',
              onClick: () => { setPrimarySortKey('strategy'); },
            },
            {
              label: 'By Tactic',
              onClick: () => { setPrimarySortKey('tactic'); },
            },
            {
              label: 'By Frequency',
              onClick: () => { setPrimarySortKey('frequency'); },
            },
          ]}
        />,
        filters.strategy && (
        <ClearSelectionButton
          key="clear"
          onClick={() => { onSelect(null); }}
        />
        )]}
    >
      {sortedStrategies == null || responses.length === 0 ? <NoData />
        : (
          <Stack>
            {sortedStrategies.map(({
              id, strategy, tactic, frequency,
            }) => (
              <Item
                key={id}
                type="strategy"
                text={tactic}
                detail={strategy}
                value={frequency}
                isSelected={id === filters.strategy}
                isDisabled={filters.strategy != null && id !== filters.strategy}
                onClick={() => {
                  onSelect(id);
                }}
                // onMoreInfoClick={() => {
                //   setExtraProps({
                //     isOpen: true,
                //     title: tactic,
                //     subtitle: strategy,
                //     children: <StrategyExtra strategyId={id} />,
                //   });
                // }}
              />
            ))}
          </Stack>
        )}
    </Panel>
  );
}

StrategiesPanel.propTypes = {
  isMinimised: PropTypes.bool,
  onMinimisedChange: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
};

StrategiesPanel.defaultProps = {
  isMinimised: false,
  onMinimisedChange: null,
};

export default StrategiesPanel;
