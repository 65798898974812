import { useMemo } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import stopword from 'stopword';
import * as d3 from 'd3';

const customStopwords = ['not', 'no', 'so', 'when'];

function generateWordCounts(data) {
  const wordCounts = {};
  data.forEach((d) => {
    const words = stopword.removeStopwords(
      d.split(/[.,/#!$%^&*;:{}=\-_`~()\s]/g)
        .map((word) => word.trim().toLowerCase())
        .filter((word) => word !== '' && !customStopwords.includes(word)),
    );
    words.forEach((word) => {
      wordCounts[word] = (wordCounts[word] || 0) + 1;
    });
  });
  return Object.entries(wordCounts)
    .map(([word, count]) => ({
      word,
      count,
    }))
    .sort((a, b) => (b.count - a.count))
    .slice(0, 50);
}

function WordCloud({ data }) {
  const wordCounts = useMemo(() => (
    generateWordCounts(data)
  ), [data]);

  const scaleFontSize = useMemo(() => (
    d3.scaleLinear()
      .domain(d3.extent(wordCounts.map((d) => d.count)))
      .range([0.75, 2])
  ), [wordCounts]);

  return (
    <Grid
      container
      spacing={1}
      justifyContent="center"
      alignItems="center"
    >
      {wordCounts.map(({ word, count }) => (
        <Grid key={word} item>
          <Typography sx={{
            fontSize: `${scaleFontSize(count)}rem`,
            color: 'accent.main',
          }}
          >
            {word}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
}

WordCloud.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default WordCloud;
