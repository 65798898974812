import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function Tooltip({ position, text }) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 50);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Box sx={{
      position: 'fixed',
      left: position[0],
      top: position[1],
      transform: 'translate(-50%, -50%)',
      pointerEvents: 'none',
      backgroundColor: 'tooltip.background',
      padding: 1,
      borderRadius: 0.5,
      zIndex: 1500,
      opacity: isVisible ? 1 : 0,
      transition: 'opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 133ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    }}
    >
      <Typography
        variant="caption"
        align="center"
        sx={{
          color: (theme) => theme.palette.getContrastText(theme.palette.grey[900]),
        }}
      >
        {text}
      </Typography>
    </Box>
  );
}

Tooltip.propTypes = {
  position: PropTypes.arrayOf(PropTypes.number).isRequired,
  text: PropTypes.node.isRequired,
};

export default Tooltip;
