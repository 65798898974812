import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material';
import { darkTheme } from 'theme';
import splashBackgroundImage from 'lib/images/splash-background.jpg';
import splashPopImage from 'lib/images/splash-pop.jpg';

function Paragraph({ children, ...props }) {
  return <Typography variant="body1" sx={{ marginBottom: 2.5 }} align="center" {...props}>{children}</Typography>;
}

Paragraph.propTypes = {
  children: PropTypes.node,
};

Paragraph.defaultProps = {
  children: null,
};

function SplashModal({ isOpen, setIsOpen }) {
  return (
    <Modal open={isOpen} onClose={() => { setIsOpen(false); }}>
      <Box>
        <ThemeProvider theme={darkTheme}>
          <Paper
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 0.8,
              height: 0.8,
              maxWidth: 900,
              maxHeight: 700,
            }}
            elevation={0}
          >
            <Paper
              sx={{
                position: 'absolute',
                top: '10%',
                left: '-5%',
                width: '110%',
                height: '80%',
                zIndex: -2,
                backgroundSize: 'cover',
                backgroundImage: `url(${splashPopImage})`,
              }}
              elevation={0}
            />
            <Paper
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: -1,
                padding: 8,
                backgroundSize: 'cover',
                backgroundImage: `url(${splashBackgroundImage})`,
                overflowY: 'auto',
              }}
              elevation={0}
              square
            >
              <Typography
                variant="h1"
                align="center"
                sx={{
                  '&::before': {
                    content: '""',
                    marginLeft: '-100%',
                  },
                  '&::after': {
                    content: '""',
                    marginRight: '-100%',
                  },
                }}
              >
                Welcome

              </Typography>
              <Typography variant="h5" sx={{ marginBottom: 4 }} align="center" color="primary">to the Small Business and Worker Mobility Project.</Typography>
              <Paragraph>
                The underlying goal of this initiative is to identify ways in which the small business ecosystem
                can support small businesses to improve the quality of life and advance
                the economic mobility of their workers.
              </Paragraph>
              <Paragraph>
                This tool is one way that we have visualized data resulting from 200 surveys of small business owners
                and 50 interviews with small business intermediaries.
              </Paragraph>
              <Paragraph>
                The database is designed to show patterns between business types and the strategies that will most
                likely succeed at having them adopt employment practices that improve job quality for their workers.
              </Paragraph>
              <Paragraph>
                The database can also sort by influencers, members of the small business ecosystem
                who can implement strategies that nudge businesses to adopt certain employment practices.
              </Paragraph>
              <Paragraph>
                Take a look, poke around, and enjoy.
              </Paragraph>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button variant="outlined" color="secondary" size="large" onClick={() => { setIsOpen(false); }}>Enter</Button>
              </Box>
            </Paper>
          </Paper>
        </ThemeProvider>
      </Box>
    </Modal>
  );
}

SplashModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default SplashModal;
