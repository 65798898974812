export function getRandomInt(min, max) {
  return min + Math.floor(Math.random() * (max - min));
}

export function getSample(array, min, max) {
  const samples = [];
  const numSamples = getRandomInt(min, max);
  while (samples.length < numSamples) {
    const index = getRandomInt(0, array.length);
    const sample = array[index];
    if (!samples.includes(sample)) samples.push(sample);
  }
  return samples;
}

export function getUnique(array) {
  return [...new Set(array)];
}

export function getUniqueBy(array, getKey) {
  const result = [];
  const map = new Map();
  array.forEach((item) => {
    const key = typeof getKey === 'function' ? getKey(item) : item[getKey];
    if (!map.has(key)) {
      map.set(key, true);
      result.push(item);
    }
  });
  return result;
}

export function getSum(array) {
  return array.reduce((total, value) => (
    total + value
  ), 0);
}

export function doArraysHaveSameMembers(arrayA, arrayB) {
  const tempB = [...arrayB];
  const isEveryAInB = arrayA.every((a) => {
    const index = arrayB.indexof(a);
    if (index === -1) return false;
    tempB.splice(index, 1);
    return true;
  });
  return isEveryAInB && tempB.length === 0;
}

export function compare(a, b, order = 'asc') {
  if (a < b) return order === 'desc' ? 1 : -1;
  if (b < a) return order === 'desc' ? -1 : 1;
  return 0;
}

export function sortBy(array, keys, order) {
  return [...array].sort((a, b) => {
    let comparison;
    keys.some((key, i) => {
      comparison = compare(a[key], b[key], order?.[i] === 'desc' ? 'desc' : 'asc');
      return Boolean(comparison);
    });
    return comparison;
  });
}

export function trim(value) {
  if (typeof value === 'string') return value.trim();
  return value;
}

export function inRange(min, max, value, isInclusive = false) {
  return value >= min && (isInclusive ? value <= max : value < max);
}
