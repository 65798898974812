import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import AppBar from 'components/AppBar';

function App({ children, isLoading, onHelpClick }) {
  return (
    <Box sx={{
      bgcolor: 'background.main',
      margin: 0,
      width: '100vw',
      minHeight: '100vh',
      padding: 4,
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
    }}
    >
      <Box sx={{ flex: 'none' }}>
        <AppBar onHelpClick={onHelpClick} />
      </Box>
      <Box sx={{ flex: 1, display: 'flex' }}>
        {children}
      </Box>
      <Backdrop open={isLoading}>
        <CircularProgress size={80} sx={{ color: 'white' }} />
      </Backdrop>
    </Box>
  );
}

App.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  onHelpClick: PropTypes.func.isRequired,
};

App.defaultProps = {
  children: null,
  isLoading: false,
};

export default App;
