import { useCallback, useMemo, useState } from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import App from 'components/App';
import AttributesPanel from 'components/AttributesPanel';
import DataContext from 'components/DataContext';
import Extra, { ExtraContext } from 'components/Extra';
import FiltersContext from 'components/FiltersContext';
import InfluencersPanel from 'components/InfluencersPanel';
import MindsetsPanel from 'components/MindsetsPanel';
import PracticesPanel from 'components/PracticesPanel';
import ResponseContext from 'components/ResponseContext';
import ResponsesInfo from 'components/ResponsesInfo';
import SplashModal from 'components/SplashModal';
import StrategiesPanel from 'components/StrategiesPanel';
import TopTab from 'components/TopTab';
import { styled, useTheme } from '@mui/material/styles';
import { useQuery } from '@apollo/client';
import { GET_DATA } from 'lib/queries';
import {
  getFilteredResponses, transformRawDataToAttributes, transformRawDataToInfluencers,
  transformRawDataToMindsets, transformRawDataToResponses, transformRawDataToPractices,
  transformRawDataToStrategies,
} from 'lib/data-helpers';

const RowBreak = styled(Box)(({ theme }) => ({
  flexBasis: '100%',
  height: 0,
  [theme.breakpoints.down('md')]: {
    display: 'block',
  },
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

const PanelsColumn = styled(Box)(() => ({
  flexGrow: 6,
  flexBasis: 0,
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '100%',
}));

const PanelContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMinimised',
})(({ isMinimised }) => ({
  flexGrow: isMinimised ? 0 : 6,
  flexBasis: isMinimised ? 'auto' : 0,
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  minHeight: isMinimised ? 'min-content' : 300,
  maxWidth: '100%',
}));

function ViewerApp() {
  const theme = useTheme();

  const [isSplashOpen, setIsSplashOpen] = useState(true);
  const [extraProps, setExtraProps] = useState({ isOpen: false });
  const [minimised, setMinimised] = useState({
    mindsets: false,
    influencers: false,
    practices: false,
    strategies: false,
  });
  const [filters, setFilters] = useState({
    attributes: {},
    influencer: null,
    practice: null,
    strategy: null,
  });

  const { loading, error, data } = useQuery(GET_DATA);

  // eslint-disable-next-line no-console
  if (error) console.error(error);

  const allResponses = useMemo(() => (
    data == null ? null : transformRawDataToResponses(data)
  ), [data]);

  const mindsets = useMemo(() => (
    data == null ? null : transformRawDataToMindsets(data)
  ), [data]);

  const attributes = useMemo(() => (
    data == null ? null : transformRawDataToAttributes(data)
  ), [data]);

  const influencers = useMemo(() => (
    data == null ? null : transformRawDataToInfluencers(data)
  ), [data]);

  const practices = useMemo(() => (
    data == null ? null : transformRawDataToPractices(data)
  ), [data]);

  const strategies = useMemo(() => (
    data == null ? null : transformRawDataToStrategies(data)
  ), [data]);

  const onSubmit = useCallback((attributeSelections) => {
    setFilters({
      attributes: attributeSelections,
      influencer: null,
      practice: null,
      strategy: null,
    });
  }, []);

  const onSelect = useCallback((type, selection) => {
    setFilters({
      ...filters,
      [type]: filters[type] === selection ? null : selection,
    });
  }, [filters]);

  const filteredResponses = useMemo(() => (
    allResponses == null ? null : getFilteredResponses(allResponses, filters, attributes)
  ), [allResponses, attributes, filters]);

  const onMinimisedChange = useCallback((type) => {
    setMinimised({
      ...minimised,
      [type]: !minimised[type],
    });
  }, [minimised]);

  return (
    <ExtraContext.Provider value={setExtraProps}>
      <DataContext.Provider value={{
        attributes, mindsets, influencers, practices, strategies,
      }}
      >
        <ResponseContext.Provider value={filteredResponses}>
          <FiltersContext.Provider value={filters}>
            {filteredResponses && (
            <TopTab>
              <ResponsesInfo />
            </TopTab>
            )}
            <App isLoading={!isSplashOpen && loading} onHelpClick={() => { setIsSplashOpen(true); }}>
              <Box sx={{
                display: 'flex',
                columnGap: 8,
                rowGap: 4,
                flexGrow: 1,
                flexWrap: 'wrap',
                maxWidth: '100%',
              }}
              >
                <Box sx={{
                  flexGrow: 4,
                  flexBasis: 0,
                  overflowX: 'hidden',
                  display: 'flex',
                  flexDirection: 'column',
                  maxWidth: '100%',
                }}
                >
                  <PanelContainer>
                    <AttributesPanel
                      onSubmit={onSubmit}
                      attributes={attributes}
                      responses={allResponses}
                    />
                  </PanelContainer>
                </Box>
                <RowBreak />
                <Box sx={{
                  flexGrow: 8,
                  flexBasis: 0,
                  display: 'flex',
                  columnGap: 4,
                  [theme.breakpoints.down('md')]: {
                    rowGap: 2,
                  },
                  [theme.breakpoints.up('md')]: {
                    rowGap: 4,
                  },
                  flexWrap: 'wrap',
                  maxWidth: '100%',
                }}
                >
                  <PanelsColumn rowGap={4}>
                    <PanelContainer isMinimised={minimised.mindsets}>
                      <MindsetsPanel
                        mindsets={mindsets}
                        isMinimised={minimised.mindsets}
                        onMinimisedChange={() => { onMinimisedChange('mindsets'); }}
                      />
                    </PanelContainer>
                    <PanelContainer isMinimised={minimised.practices}>
                      <PracticesPanel
                        isMinimised={minimised.practices}
                        onMinimisedChange={() => { onMinimisedChange('practices'); }}
                        onSelect={(value) => { onSelect('practice', value); }}
                      />
                    </PanelContainer>
                  </PanelsColumn>
                  <RowBreak />
                  <PanelsColumn rowGap={4}>
                    <PanelContainer isMinimised={minimised.influencers}>
                      <InfluencersPanel
                        influencers={influencers}
                        isMinimised={minimised.influencers}
                        onMinimisedChange={() => { onMinimisedChange('influencers'); }}
                        onSelect={(value) => { onSelect('influencer', value); }}
                      />
                    </PanelContainer>
                    <PanelContainer isMinimised={minimised.strategies}>
                      <StrategiesPanel
                        isMinimised={minimised.strategies}
                        onMinimisedChange={() => { onMinimisedChange('strategies'); }}
                        onSelect={(value) => { onSelect('strategy', value); }}
                      />
                    </PanelContainer>
                  </PanelsColumn>
                </Box>
              </Box>
            </App>
            <Extra {...extraProps} />
            <SplashModal isOpen={isSplashOpen} setIsOpen={setIsSplashOpen} />
            <Snackbar open={error != null}>
              <Alert severity="error">
                Error loading data
              </Alert>
            </Snackbar>
          </FiltersContext.Provider>
        </ResponseContext.Provider>
      </DataContext.Provider>
    </ExtraContext.Provider>
  );
}

export default ViewerApp;
