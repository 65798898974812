import { useContext } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MoreInfoButton from 'components/MoreInfoButton';
import ResponseContext from 'components/ResponseContext';
import { ExtraContext } from 'components/Extra';
import ResponsesExtra from 'components/ResponsesExtra';

function ResponsesInfo() {
  const responses = useContext(ResponseContext);
  const setExtraProps = useContext(ExtraContext);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Typography>{`${responses.length === 0 ? 'No' : 'Filtered'} Responses`}</Typography>
      <MoreInfoButton onClick={() => {
        setExtraProps({
          isOpen: true,
          title: 'Responses',
          children: <ResponsesExtra />,
        });
      }}
      />
    </Box>
  );
}

export default ResponsesInfo;
