import { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ClearSelectionButton from 'components/ClearSelectionButton';
import FiltersContext from 'components/FiltersContext';
import Item from 'components/Item';
import NoData from 'components/NoData';
import Panel from 'components/Panel';
import ResponseContext from 'components/ResponseContext';
import SortButton from 'components/SortButton';
import { sortBy } from 'lib/utils';

const primarySortKeyToKeys = {
  influencer: ['subtype', 'influencer', 'frequency,'],
  frequency: ['frequency', 'subtype', 'influencer'],
};

const keyToOrder = {
  subtype: 'asc',
  influencer: 'asc',
  frequency: 'desc',
};

function getInfluencers(responses) {
  const influencers = {};
  const responsesByInfluencer = {};

  responses.forEach((r) => {
    r.influencers.forEach((i) => {
      influencers[i.id] = i;
      if (responsesByInfluencer[i.id] == null) responsesByInfluencer[i.id] = new Set();
      responsesByInfluencer[i.id].add(r.id);
    });
  });

  return Object.entries(influencers).map(([id, i]) => ({
    ...i,
    frequency: responsesByInfluencer[id].size / responses.length,
  }));
}

function InfluencersPanel({
  isMinimised, onMinimisedChange, onSelect,
}) {
  const filteredResponses = useContext(ResponseContext);
  const filters = useContext(FiltersContext);

  const [primarySortKey, setPrimarySortKey] = useState('frequency');

  const influencers = useMemo(() => (
    filteredResponses == null ? null : getInfluencers(filteredResponses)
  ), [filteredResponses]);

  const sortedInfluencers = useMemo(() => (
    influencers == null ? null : sortBy(
      influencers,
      primarySortKeyToKeys[primarySortKey],
      primarySortKeyToKeys[primarySortKey].map((key) => keyToOrder[key]),
    )
  ), [influencers, primarySortKey]);

  return (
    <Panel
      title="Influencers"
      titleTooltip="Organizations and entities that directly or indirectly support small businesses and are uniquely positioned to influence or incentivize them to adopt mobility-boosting employment practices."
      isMinimised={isMinimised}
      onMinimisedChange={onMinimisedChange}
      buttons={[
        <SortButton
          key="sort"
          options={[
            {
              label: 'By Influencer',
              onClick: () => { setPrimarySortKey('influencer'); },
            },
            {
              label: 'By Frequency',
              onClick: () => { setPrimarySortKey('frequency'); },
            },
          ]}
        />,
        filters.influencer && (
        <ClearSelectionButton
          key="clear"
          onClick={() => { onSelect(null); }}
        />
        ),
      ]}
    >
      {sortedInfluencers == null || filteredResponses.length === 0 ? <NoData />
        : (
          <List>
            {sortedInfluencers.map(({
              id, influencer, subtype, frequency,
            }) => (
              <Item
                key={id}
                type="influencer"
                text={subtype ?? influencer}
                detail={subtype && influencer}
                value={frequency}
                isSelected={id === filters.influencer}
                isDisabled={filters.influencer != null && id !== filters.influencer}
                onClick={() => {
                  onSelect(id);
                }}
              />
            ))}
          </List>
        )}
    </Panel>
  );
}

InfluencersPanel.propTypes = {
  isMinimised: PropTypes.bool,
  onMinimisedChange: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
};

InfluencersPanel.defaultProps = {
  isMinimised: false,
  onMinimisedChange: null,
};

export default InfluencersPanel;
