import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Slider, { SliderThumb } from '@mui/material/Slider';
import Typography from '@mui/material/Typography';

function getMarks(min, max, step) {
  const marks = [];
  const low = Math.ceil(min / step) * step;
  for (let i = 0; i < max / step; i += 1) {
    marks.push(low + i * step);
  }
  return marks.map((value) => ({ value }));
}

function AttributeRangeSlider({ options, onChange, label }) {
  const [min, max] = useMemo(() => (
    [Math.min(...options), Math.max(...options)]
  ), [options]);

  const marks = useMemo(() => (
    getMarks(min, max, 5)
  ), [max, min]);

  const [isUsed, setIsUsed] = useState(false);
  const [value, setValue] = useState([min, max]);

  return (
    <FormControl>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
      }}
      >
        <FormLabel sx={{ marginTop: 2 }}>{label}</FormLabel>
        <Checkbox
          sx={{ transform: (theme) => `translate(${theme.spacing(1)}, ${theme.spacing(1)})` }}
          size="small"
          checked={isUsed}
          onChange={(e) => { setIsUsed(e.target.checked); onChange(e.target.checked ? value : null); }}
        />
      </Box>
      <Collapse in={isUsed}>
        <Slider
          sx={{
            marginTop: 4,
          }}
          min={min}
          max={max}
          step={1}
          marks={marks}
          components={{
            Thumb,
          }}
          disabled={!isUsed}
          value={value}
          onChange={(e, newValue) => { setValue(newValue); }}
          onChangeCommitted={(e, newValue) => { onChange(isUsed ? newValue : null); }}
        />
      </Collapse>
    </FormControl>
  );
}

AttributeRangeSlider.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.number,
  ).isRequired,
};

export default AttributeRangeSlider;

function Thumb({ children, ...props }) {
  return (
    <SliderThumb sx={{ width: 24, height: 24 }} {...props}>
      {children}
      <Typography sx={{
        fontSize: 12,
        color: 'white',
      }}
      >
        {props.ownerState.value[props['data-index']]}
      </Typography>
    </SliderThumb>
  );
}

Thumb.propTypes = {
  children: PropTypes.node,
  'data-index': PropTypes.number.isRequired,
  ownerState: PropTypes.shape({
    value: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
};

Thumb.defaultProps = {
  children: null,
};
