/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const GET_DATA = gql`
  query GetData {
    survey_response {
      respondent_id
      response_attributes {
        attribute_id
        attribute {
          attribute_category
          attribute_type
          attribute
        }
      }
      response_influencers {
        influencer_id
        influencer {
          influencer
          influencer_subtype
        }
      }
      response_practices {
        practice_id
        practice {
          practice_category
          practice
        }
      }
      response_strategies {
        strategy_id
        strategy {
          strategy
          tactic
        }
      }
      response_mindsets {
        mindset_id
        mindset {
          question
          answer
        }
      }
      response_open_endeds {
        attribute_id
        influencer_id
        practice_id
        strategy_id
        mindset_id
        open_ended
      }
    }
    attributes {
      database_code
      attribute_category
      attribute_type
      attribute
      is_filter
    }
    influencers {
      database_code
      influencer
      influencer_subtype
    }
    practices {
      database_code
      practice_category
      practice
    }
    strategies {
      database_code
      strategy
      tactic
    }
    mindsets {
      database_code
      question
      answer
      chart_type
    }
  }
`;
