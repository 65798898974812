import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const urbaneColors = {
  midnightPrimary: '#101b26',
  nightTonal: '#1b2f41',
  violetPrimary: '#593478',
  eggplantTonal: '#2b1c38',
  coralPrimary: '#fd7e64',
  duskTonal: '#23465e',
  black: '#000000',
  grey: '#f5f5f5',
  white: '#ffffff',
};

const theme = responsiveFontSizes(createTheme({
  palette: {
    primary: {
      main: urbaneColors.violetPrimary,
    },
    secondary: {
      main: urbaneColors.coralPrimary,
    },
    accent: {
      main: urbaneColors.duskTonal,
    },
    background: {
      main: urbaneColors.grey,
    },
    grey: {
      main: '#898989',
      light: '#cccccc',
    },
    tooltip: {
      background: 'rgba(0,0,0,.8)',
    },
  },
  typography: {
    fontFamily: [
      'Open Sans',
      'sans-serif',
    ].join(','),
    h1: {
      fontFamily: [
        'PT Serif',
        'serif',
      ].join(','),
      fontSize: '5.5rem',
    },
    h4: {
      fontFamily: [
        'PT Serif',
        'serif',
      ].join(','),
    },
    h5: {
      fontFamily: [
        'PT Serif',
        'serif',
      ].join(','),
    },
    h6: {
      fontFamily: [
        'Kanit',
        'sans-serif',
      ].join(','),
    },
    button: {
      fontFamily: [
        'Kanit',
        'sans-serif',
      ].join(','),
      fontWeight: 'bold',
      letterSpacing: '0.05em',
      textTransform: 'uppercase',
      fontSize: '14px',
    },
    overline: {
      fontFamily: [
        'Kanit',
        'sans-serif',
      ].join(','),
      textTransform: 'none',
      color: '#898989',
      fontWeight: 'bold',
      letterSpacing: '0.05rem',
      lineHeight: 'normal',
    },
    number: {
      fontFamily: [
        'PT Serif',
        'serif',
      ].join(','),
      color: '#898989',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        outlined: {
          borderWidth: 4,
          '&:hover': {
            borderWidth: 4,
            backgroundColor: 'transparent',
          },
        },
      },
    },
  },
}));

export default theme;

export const darkTheme = createTheme(theme, {
  palette: {
    mode: 'dark',
    primary: {
      main: urbaneColors.coralPrimary,
    },
    secondary: {
      main: urbaneColors.white,
    },
    text: {
      primary: urbaneColors.white,
    },
    background: {
      paper: urbaneColors.midnightPrimary,
    },
  },
});
