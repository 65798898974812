import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import * as d3 from 'd3';

const maxNumBars = 3;
const barPaddingInner = 0.2;
const barPaddingOuter = 0;

function FrequencyIndicator({
  value, width, height, color,
}) {
  const theme = useTheme();

  const scaleBand = useMemo(() => (
    d3.scaleBand()
      .domain(Array.from(Array(maxNumBars)).map((d, i) => i))
      .range([height, 0])
      .paddingInner(barPaddingInner)
      .paddingOuter(barPaddingOuter)

  ), [height]);

  const numBars = useMemo(() => (
    Math.ceil(value * maxNumBars)
  ), [value]);

  const bars = useMemo(() => (
    Array.from(Array(maxNumBars)).map((d, i) => (
      <rect
        key={i}
        y={scaleBand(i)}
        width={width}
        height={scaleBand.bandwidth()}
        fill={color || theme.palette.text.primary}
        opacity={i < numBars ? 1 : 0.2}
      />
    ))
  ), [color, numBars, scaleBand, theme.palette.text.primary, width]);

  return (
    <svg width={width} height={height}>
      {bars}
    </svg>
  );
}

FrequencyIndicator.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  value: PropTypes.number.isRequired,
  width: PropTypes.number,
};

FrequencyIndicator.defaultProps = {
  color: null,
  height: 50,
  width: 30,
};

export default FrequencyIndicator;
