import { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ClearSelectionButton from 'components/ClearSelectionButton';
import FiltersContext from 'components/FiltersContext';
import Item from 'components/Item';
import NoData from 'components/NoData';
import Panel from 'components/Panel';
import ResponseContext from 'components/ResponseContext';
import SortButton from 'components/SortButton';
import { sortBy } from 'lib/utils';
import { getPractices } from 'lib/common';

const primarySortKeyToKeys = {
  practice: ['practice', 'frequency,', 'category'],
  category: ['category', 'frequency', 'practice'],
  frequency: ['frequency', 'category', 'practice'],
};

const keyToOrder = {
  practice: 'asc',
  category: 'asc',
  frequency: 'desc',
};

function PracticesPanel({
  isMinimised, onMinimisedChange, onSelect,
}) {
  const responses = useContext(ResponseContext);
  const filters = useContext(FiltersContext);

  const [primarySortKey, setPrimarySortKey] = useState('frequency');

  const practices = useMemo(() => (
    responses == null ? null : getPractices(responses)
  ), [responses]);

  const sortedPractices = useMemo(() => (
    practices == null ? null : sortBy(
      practices,
      primarySortKeyToKeys[primarySortKey],
      primarySortKeyToKeys[primarySortKey].map((key) => keyToOrder[key]),
    )
  ), [practices, primarySortKey]);

  return (
    <Panel
      title="Employment Practices"
      titleTooltip="Actions, activities, and processes adopted by businesses that improve the economic mobility and wellbeing of low-wage workers."
      isMinimised={isMinimised}
      onMinimisedChange={onMinimisedChange}
      buttons={[
        <SortButton
          key="sort"
          options={[
            {
              label: 'By Practice',
              onClick: () => { setPrimarySortKey('practice'); },
            },
            {
              label: 'By Category',
              onClick: () => { setPrimarySortKey('category'); },
            },
            {
              label: 'By Frequency',
              onClick: () => { setPrimarySortKey('frequency'); },
            },
          ]}
        />,
        filters.practice && (
        <ClearSelectionButton
          key="clear"
          onClick={() => { onSelect(null); }}
        />
        ),
      ]}
    >
      {sortedPractices == null || responses.length === 0 ? <NoData />
        : (
          <List>
            {
        sortedPractices.map(({
          id, category, practice, frequency,
        }) => (
          <Item
            key={id}
            type="practice"
            text={practice}
            detail={category}
            value={frequency}
            isSelected={id === filters.practice}
            isDisabled={filters.practice != null && id !== filters.practice}
            onClick={() => {
              onSelect(id);
            }}
          />
        ))
      }
          </List>
        )}
    </Panel>
  );
}

PracticesPanel.propTypes = {
  isMinimised: PropTypes.bool,
  onMinimisedChange: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
};

PracticesPanel.defaultProps = {
  isMinimised: false,
  onMinimisedChange: null,
};

export default PracticesPanel;
